<template>
  <div class="clue-distribution">
    <div style="position: sticky; z-index: 20; top: 44px;">
      <search-bar-new
        :placeholder-text="$t('请输入客户姓名、电话')"
        :active="Object.values(filterParams).length > 0"
        :str="searchStr"
        :showScreen="true"
        :showCategory="false"
        @input="onInput"
        @search="onRefresh"
        @screening="filterPanelVisible = true"
        @operation="operation">
      </search-bar-new>
      <!-- 筛选面板 -->
      <FilterPanel
        ref="filterPanel"
        v-model="filterPanelVisible"
        :options="filterOptions"
        @submit="onFilter"
      />
      <VanTabs
        :queryType="activeTab"
        :tabList="tabList"
        @change="changeTab">
      </VanTabs>
    </div>
    <ListSort style="position: sticky; z-index: 20; top: 132px;" :options="sortOptions" @change="onSort"></ListSort>
    <List ref="list" :key="activeTab || 1" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <ClueItem :clue="item" @change="onCheckChanged" @goDetail="goDetail"/>
      </template>
    </List>
    <van-goods-action v-if="userInfo.type === 1">
      <div class="bottom-btns">
        <div class="left">
          <van-checkbox :value="isAll" @click="isAllChange">{{ $t('全选') }}</van-checkbox>
        </div>
        <div class="right" @click="onDistribution">{{ $t('分配') }}{{ distributionIds.length>0?`(${distributionIds.length})`:'' }}</div>
      </div>
    </van-goods-action>
    <BusinessFilters ref="filter" v-model="showDistribution"
      :options="distributionOptions"
      :defaultActiveKey="['saIdList']"
      :buttonNameReset="$t('取消')"
      :buttonNameOK="$t('确认')"
      @reset="showDistribution=false"
      @submit="distributionFilter">
    </BusinessFilters>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import SearchBarNew from '@/components/search-bar-new'
import VanTabs from '@/components/VanTabs'
import ClueDistributionServices from '@/services/clueDistributionServices'
import FilterPanel from '@/components/filter-panel'
import List from '@/components/baseList/list.vue'
import ListSort from '@/components/list-sort/Index.vue'
import ClueItem from './ClueItem.vue'
import BusinessFilters from '@/components/v2/business/BusinessFilters/index.vue'
import mixin from './mixin'
export default {
  name:'ClueDistribution',
  components:{
    SearchBarNew,
    VanTabs,
    FilterPanel,
    List,
    ClueItem,
    BusinessFilters,
    ListSort
  },
  mixins:[mixin],
  props:{
    outsideTab:{
      type: Array,
      default:()=>([])
    },
    tabValue: {
      type: [Number,String],
      default: ''
    }
  },
  data(){
    const that = this
    return {
      searchStr:'',
      activeTab: 1,
      tabList:[
        { text: that.$t('留资线索'), value: 1, total:0, key:'leadCount' },
        { text: that.$t('预约试驾线索'), value: 2, total: 0 , key:'leadAppointment' },
      ],
      filterParams: {},
      showDistribution:false,
      distributionIds:[],
      list:[],
      distributionOptions:[{
        name: '',
        type: 'B_PEOPLE_SELECTION',
        field: 'saIdList',
        isSelection: true,
        valueType: 'object',
        shopIds:'',
        height:'80vh',        
        get roleCodes() {
          return [that.activeTab===1?'1014001,1014002':'1014002'] // 角色对应的code
        }
      }],
      sort: [{
        field: 'distribute_time',
        asc: false
      }],
      sortOptions: [
        {
          field: 'distribute_time',
          text: this.$t('下发时间'),
          order: 'descend'
        }
      ],
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    isAll() {
      return this.distributionIds.length===this.list.length && this.distributionIds.length!==0
    },
    getUserInfo() {
      return this.$store.getters.userInfo
    },
  },
  watch: {
    userInfo: {
      handler(val) {
        if (val?.type === 0) {
          this.init()
        }
      },
      immediate: true
    }
  },
  created() {
    const [one] = this.outsideTab
    this.activeTab = Number(one || 1)
  },
  mounted(){
    this.getListCount()
  },
  methods:{
    onSort(data) {
      this.sort = data
      this.onRefresh()
    },
    onInput(value) {
      this.searchStr = value
    },
    onRefresh(){
      this.distributionIds = []
      this.$refs.list.onRefresh()
      this.getListCount()
    },
    operation(){},
    // 获取 留资/预约试驾线索 数量
    async getListCount(){
      const params = {
        searchStr:this.searchStr,
        ...this.filterParams,
      }
      const data = await ClueDistributionServices.getClueDistributionCount(params)
      this.tabList[0].total = data?.leadCount||0
      this.tabList[1].total = data?.leadAppointment||0
      this.$emit('setCount', { toBeAllocated: (this.tabList[0].total + this.tabList[1].total) })
    },
    // 切换 tab需要重新请求列表数据
    changeTab(val){
      this.distributionIds = []
      this.activeTab=val
      this.onRefresh()
    },    
    // 获取列表数据
    fetchApi(obj={}) {
      const params = {
        sort: this.sort,
        ...this.filterParams,
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        searchStr:this.searchStr,
        isAppointment:this.activeTab===1?0:1
      }
      return ClueDistributionServices.getclueList(params).then(res => {
        res.dataList.forEach((item)=>{
          item.checked=false
        })
        this.list = this.$refs.list.list.concat(res.dataList)
        console.log('this.list:',this.list)
        return res
      })
    },
    // 勾选/取消线索
    onCheckChanged(checked,theClue){
      const ids = []
      this.$refs.list.list.forEach(item=>{
        if (item.id===theClue.id){
          item.checked=checked
        }
        if (item.checked){
          ids.push(item.id)
        }
      })
      this.distributionIds = ids
    },
    // 全选/取消全选
    isAllChange(){
      const ids = []
      const flag = !this.isAll
      this.$refs.list.list.forEach(item=>{
        item.checked=flag
        if (item.checked){
          ids.push(item.id)
        }
      })
      this.distributionIds = ids
    },
    // 打开员工列表
    onDistribution(){
      // 判断是否选中线索
      this.distributionIds = []
      this.$refs.list.list.forEach(item => {
        if (item.checked){
          this.distributionIds.push(item.id)
        }
      })
      
      if (!this.distributionIds.length) return this.$toast(this.$t('请选择待分配的线索'))
      this.showDistribution=true
    },
    async distributionFilter({ saIdList }){
      const [{ id,name }] = saIdList
      const params = {
        empId:id,
        empName:name,
        ids:this.distributionIds,
      }
      const res = await ClueDistributionServices.handleDistribution(params)
      this.$toast.success(this.$t('分配成功'))
      this.onRefresh()
    },
    // 跳转至详情
    goDetail({ id }) {
      if (this.getUserInfo.isManager || this.userInfo.type === 0) {
        this.$router.push({
          path: '/distribution-detail',
          query: {
            id,
            activeTab: this.activeTab
          },
        })
      }
    },
  }
}
</script>
<style lang="less" scoped>
</style>

